.toc-container {
  border: 2px solid #776d6d;
  border-radius: 10px;
  padding: 20px;
}

.toc-title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #776d6d;
}

.toc-list li {
  color: rgb(93, 89, 89);
  font-size: 15px;
  margin: 10px 0;
}

.toc-list a {
  color: rgb(93, 89, 89);
  text-decoration: none; 
}


.toc-list ol {
  list-style-type: none;
  padding-left: 20px;
}
